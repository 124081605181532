import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../../Dashboard/Pages/Dashboard";
import Accounts from "../../Accounts/Pages/Accounts";
import Notices from "../../Notices/Pages/Notices";
import Residences from "../../Residences/Pages/Residences";
import NewResidence from "../../Residences/Pages/NewResidence";
import DeleteResidence from "../../Residences/Pages/DeleteResidence";
import EditResidence from "../../Residences/Pages/EditResidence";
import EditResidenceBalance from "../../Residences/Pages/EditResidenceBalance";
import EditResidenceDiscount from "../../Residences/Pages/EditResidenceDiscount";
import ResidenceDetails from "../../Residences/Pages/ResidenceDetails";
import NewAccount from "../../Accounts/Pages/NewAccount";
import EditAccount from "../../Accounts/Pages/EditAccount";
import EditPayout from "../../Accounts/Pages/EditPayout";
import DeleteAccount from "../../Accounts/Pages/DeleteAccount";
import NewNotice from "../../Notices/Pages/NewNotice";
import NewUser from "../../Users/Pages/NewUser";
import EditUser from "../../Users/Pages/EditUser";
import DeleteUser from "../../Users/Pages/DeleteUser";
import ManualPayments from "../../Payments/Pages/ManualPayments";
import CardPayments from "../../Payments/Pages/CardPayments";
import NewManualPayment from "../../Payments/Pages/NewManualPayment";
import EditManualPayment from "../../Payments/Pages/EditManualPayment";
import Reports from "../../Reports/Pages/Reports";
import NewReport from "../../Reports/Pages/NewReport";
import SurveyReport from "../../Reports/Pages/SurveyReport";
import FinanceReport from "../../Reports/Pages/FinanceReport";
import TimeLineReport from "../../Reports/Pages/TimeLineReport";
import MorosityReport from "../../Reports/Pages/MorosityReport";
import PaymentsReport from "../../Reports/Pages/PaymentsReport";
import MyAccount from "../../MyAccount/Pages/MyAccount";
import Tickets from "../../Tickets/Pages/Tickets";
import NewTicket from "../../Tickets/Pages/NewTicket";
import EditTicket from "../../Tickets/Pages/EditTicket";
import Expenses from "../../Expenses/Pages/Expenses";
import NewExpense from "../../Expenses/Pages/NewExpense";
import EditExpense from "../../Expenses/Pages/EditExpense";
import DeleteExpense from "../../Expenses/Pages/DeleteExpense";

function MainContent() {
  return (
    <Container>
      <Row>
        <Col>
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/my-account" element={<MyAccount />}></Route>
            <Route path="/accounts" element={<Accounts />}></Route>
            <Route path="/notices" element={<Notices />}></Route>
            <Route path="/residences" element={<Residences />}></Route>
            <Route
              path="/residence-details/:rid"
              element={<ResidenceDetails />}
            ></Route>
            <Route path="/new-residence" element={<NewResidence />}></Route>
            <Route
              path="/edit-residence/:rid"
              element={<EditResidence />}
            ></Route>
            <Route
              path="/edit-residence/balance/:rid"
              element={<EditResidenceBalance />}
            ></Route>
            <Route
              path="/edit-residence/discount/:rid"
              element={<EditResidenceDiscount />}
            ></Route>
            <Route
              path="/delete-residence/:rid"
              element={<DeleteResidence />}
            ></Route>
            <Route path="/new-account" element={<NewAccount />}></Route>
            <Route path="/edit-account/:aid" element={<EditAccount />}></Route>
            <Route
              path="/edit-account-payout/:aid"
              element={<EditPayout />}
            ></Route>
            <Route
              path="/delete-account/:aid"
              element={<DeleteAccount />}
            ></Route>
            <Route path="/new-notice" element={<NewNotice />}></Route>
            <Route path="/new-user/:rid" element={<NewUser />}></Route>
            <Route path="/edit-user/:uid" element={<EditUser />}></Route>
            <Route path="/delete-user/:uid" element={<DeleteUser />}></Route>
            <Route
              path="/card-payments/:rid"
              element={<CardPayments />}
            ></Route>
            <Route
              path="/manual-payments/:rid"
              element={<ManualPayments />}
            ></Route>
            <Route
              path="/new-manual-payment/:rid"
              element={<NewManualPayment />}
            ></Route>
            <Route
              path="/edit-manual-payment"
              element={<EditManualPayment />}
            ></Route>
            <Route path="/reports" element={<Reports />}></Route>
            <Route path="/new-report" element={<NewReport />}></Route>
            <Route
              path="/report-survey-details/:rid"
              element={<SurveyReport />}
            ></Route>
            <Route
              path="/report-finance-details/:rid"
              element={<FinanceReport />}
            ></Route>
            <Route
              path="/report-timeline-details/:rid"
              element={<TimeLineReport />}
            ></Route>
            <Route
              path="/report-morosity-details/:rid"
              element={<MorosityReport />}
            ></Route>
            <Route
              path="/report-payments-details/:rid"
              element={<PaymentsReport />}
            ></Route>
            <Route path="/tickets" element={<Tickets />}></Route>
            <Route path="/new-ticket" element={<NewTicket />}></Route>
            <Route path="/edit-ticket/:tid" element={<EditTicket />}></Route>
            <Route path="/expenses" element={<Expenses />}></Route>
            <Route path="/new-expense" element={<NewExpense />}></Route>
            <Route path="/edit-expense/:eid" element={<EditExpense />}></Route>
            <Route
              path="/delete-expense/:eid"
              element={<DeleteExpense />}
            ></Route>
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

export default MainContent;
