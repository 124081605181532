import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

function EditTicket() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState("");
  const [status, setStatus] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { tid } = useParams();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setAccounts(response.data.accounts);
      setTitle(result.data.ticket.title);
      setDescription(result.data.ticket.description);
      setStatus(result.data.ticket.status);
      setAccount(result.data.ticket.account);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const closeTicket = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/close/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/tickets");
    } catch (error) {
      console.log(error);
    }
  };

  const openTicket = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/open/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/tickets");
    } catch (error) {
      console.log(error);
    }
  };

  const handleTitleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const handleAccountChange = (evt) => {
    setAccount(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/`,
        data: {
          title: title,
          description: description,
          account: account,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/tickets");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-ticket">
        <Col>
          <h4>Nuevo ticket</h4>
        </Col>
        <Col className="new-ticket-form">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título del ticket"
                onChange={handleTitleChange}
                value={title}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Descripción del ticket"
                onChange={handleDescriptionChange}
                value={description}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Fraccionamiento</Form.Label>
              <Form.Select onChange={handleAccountChange} value={account}>
                <option key={""} value={""}>
                  Seleccionar fraccionamiento
                </option>
                {accounts.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Button variant="info" onClick={handleSubmit}>
                Guardar
              </Button>
              <Button variant="danger" onClick={() => navigate("/tickets")}>
                Cancelar
              </Button>
              {status === "open" ? (
                <Button variant="primary" onClick={() => closeTicket()}>
                  Cerrar ticket
                </Button>
              ) : (
                <Button variant="primary" onClick={() => openTicket()}>
                  Abrir ticket
                </Button>
              )}
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default EditTicket;
