import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";
const dayjs = require("dayjs");

function Ticket({
  id,
  title,
  description,
  date,
  userName,
  accountName,
  status,
  deleteTicket,
}) {
  return (
    <Col className={`ticket-element-${status} mb-2`}>
      <Col>
        <Row>
          <Col>{dayjs(date).format("DD-MMM-YYYY")}</Col>
          <Col className="right">{userName}</Col>
        </Row>
      </Col>
      <Col className="mt-2">
        <NavLink to={`/edit-ticket/${id}`}>
          <h4 className="mb-2">{title}</h4>
        </NavLink>
        <h6>{accountName}</h6>
        <p>{description}</p>
      </Col>
    </Col>
  );
}

export default Ticket;
